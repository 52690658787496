import React from "react"

export default function Mention(props) {
  return (
    <>
      <a
        href={props.mention.link}
        className="my-3 md:m-0 rounded border-2 border-black border-solid overflow-hidden shadow-md transition-all duration-300 hover:shadow-lg hover:border-pink transform hover:-translate-y-2"
        target="_blank"
        rel="noreferrer"
      >
        <div className="p-6 pb-4">
          <p className="text-pink font-bold uppercase">
            {props.mention.source}
          </p>
          <h2 className="font-semibold text-2xl leading-tight">
            {props.mention.title}
          </h2>
        </div>
        <div className="px-6 pb-6">
          {props.mention.tags.map((tag, i) => {
            return (
              <span key={i} className="inline-block rounded-full text-sm font-semibold text-gray mr-2">
                #{tag}
              </span>
            )
          })}
        </div>
      </a>
    </>
  )
}
