import React from "react"
import Navbar from "../components/navbar"
import Mention from "../components/mention"
import SEO from "../components/SEO"
import { graphql } from "gatsby"

export default function Mentions(data) {
  const edges = data.data.allMentionsJson.edges
  const mentions = edges.map(edge => <Mention key={edge.node.id} mention={edge.node} />)

  return (
    <main>
      <SEO title="Mentions" pathname="/mentions" />

      <Navbar></Navbar>

      <div className="lg:max-w-screen-md mx-auto p-6 lg:pt-32 pt-24">
        <h1 className="lg:text-center tracking-tighter">
          <span className="text-pink">/</span>mentions
        </h1>
        <p className="text-xl">
          Be it for an achievement, work/social work, or interviews, I got
          mentioned a couple of times on some media outlets and publications. I
          curated a few of those instances. It sort of self-motivates me to keep
          pushing.
        </p>
      </div>

      <div
        className="md:p-6 p-6 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
        id="showcase"
      >
        {mentions}
      </div>
    </main>
  )
}

export const pageQuery = graphql`
  {
    allMentionsJson {
      edges {
        node {
          id
          link
          source
          title
          tags
        }
      }
    }
  }
`